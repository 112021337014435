import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"

import OtherCaseStudy from "../../components/other-case"

import bengu from "../../assets/img/teams/17.jpg"
import aysima from "../../assets/img/teams/35.jpg" 
import ilker from "../../assets/img/teams/47.jpg"
import zeynep from "../../assets/img/teams/33.jpg"
import dilara from "../../assets/img/teams/36.png"



import oran from "../../assets/img/mc-oranlar.png"
import mc from "../../assets/img/mc-telefon.png"
import mockup from "../../assets/img/mc-mockup.png"

const ReferancesDetail = () => (
    <Layout>
        <SEO title="Mocaco Coffee Başarı Hikayesi" />
        <div className="referances-detail py60 container">
            <div className="col-md-4 detail-left">
                <b>BAŞARI HİKAYESİ</b>
                <h1 style={{ margin: '20px 0' }}>Mocaco Coffee</h1>
                <h2 style={{ margin: '5px auto 20px' }} >Online Kahve Sipariş Noktası</h2>
                <p style={{ maxWidth: 420}}>2008 yılında "Kahveye dair her şey!" mottosu ile yola çıkan ve Ankara'da cafe işletmeciliği sektörünce kendini kanıtlamış bir marka haline gelen <span style={{fontWeight:700}}>Mocaco Coffee</span>'nin e-ticaret yolculuğunda yanında olmaktan dolayı <span style={{fontWeight:700}}>Flatart Ailesi</span> olarak gurur duymaktayız. </p>
               
                
                </div>
            <div className="col-md-8 referances-boxs">
                <div className="provided">
                    <h3 style={{ margin: '10px 0' }} >Verilen Hizmetler</h3>
                    <div className="provided-box">
                    <div className="box">
                            <Link to="/digital-ads/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Shopping-_-Cart_1" data-name="Icons-/-Shopping-/-Cart#1" transform="translate(0.452 0.373)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.452 -0.373)" fill="none" />
                                    <path id="Combined-Shape" d="M52.453,31.233l-2.2,13.227-.008.046a3.161,3.161,0,0,1-3.675,2.544L10.63,40.517a3.161,3.161,0,0,1-2.474-2.241L2.389,18.088a3.161,3.161,0,0,1,3.039-4.029H48.813l1.938-8.306a3.729,3.729,0,0,1,3.632-2.882h6.527a3.729,3.729,0,1,1,0,7.459H57.342Z" transform="translate(4.899 6.203)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Combined-Shape-2" data-name="Combined-Shape" d="M9.741,27.482a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,9.741,27.482Zm28.447,0a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,38.188,27.482Z" transform="translate(10.804 38.894)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Digital Ads
                        </div>
                        <div className="box">
                            <Link to="/icerik-pazarlama/"></Link>
                            <svg style={{marginTop:7}} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  width="50" height="50" viewBox="0 0 122.88 112.04">
                            <path id="Combined-Shape" class="cls-1" d="M109.28,19.61l12.21,9.88a3.77,3.77,0,0,1,.56,5.29l-5.46,6.75L98.53,26.93,104,20.17a3.79,3.79,0,0,1,5.29-.56ZM21.07,30.81a3.18,3.18,0,0,1,0-6.36H74.12a3.18,3.18,0,0,1,0,6.36ZM9.49,0H85.71A9.53,9.53,0,0,1,95.2,9.49v5.63l-4.48,5.53a9.81,9.81,0,0,0-1.18,1.85c-.24.19-.48.4-.71.62V9.49a3.14,3.14,0,0,0-3.12-3.13H9.49A3.14,3.14,0,0,0,6.36,9.49v93.06a3.16,3.16,0,0,0,.92,2.21,3.11,3.11,0,0,0,2.21.92H85.71a3.12,3.12,0,0,0,3.12-3.13V88.2l1.91-.81a10,10,0,0,0,4.34-3.13l.12-.14v18.43A9.54,9.54,0,0,1,85.71,112H9.49A9.51,9.51,0,0,1,0,102.55V9.49A9.53,9.53,0,0,1,9.49,0ZM21.07,87.6a3.19,3.19,0,0,1,0-6.37H56.19a37.1,37.1,0,0,0-.3,6.37Zm0-18.93a3.19,3.19,0,0,1,0-6.37H59.22l0,.27-1.05,6.1Zm0-18.93a3.18,3.18,0,0,1,0-6.36H72.44l-5.11,6.36ZM87.25,78,74.43,83.47c-9.35,3.47-8.93,5.43-8-3.85L69.24,63.4h0l0,0,26.56-33,18,14.6L87.27,78ZM72.31,65.89l11.86,9.59-8.42,3.6c-6.6,2.83-6.42,4.23-5.27-2.53l1.83-10.66Z"/></svg>
                           İçerik Pazarlama
                        </div>
                        <div className="box">
                            <Link to="/web-mobil-gelistirme/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 101 101">
                                <g id="Icons-_-Code-_-Code" data-name="Icons-/-Code-/-Code" transform="translate(-0.115 -0.115)">
                                    <rect id="bound" width="101" height="101" transform="translate(0.115 0.115)" fill="none" />
                                    <path id="Combined-Shape" d="M69.579,14.1a4.215,4.215,0,0,1,6.138-5.777L92.576,26.238a4.215,4.215,0,0,1,0,5.777L75.717,49.927a4.215,4.215,0,1,1-6.138-5.777l14.14-15.024Zm-44.437,0L11,29.127,25.142,44.15A4.215,4.215,0,1,1,19,49.927L2.146,32.015a4.215,4.215,0,0,1,0-5.777L19,8.326A4.215,4.215,0,1,1,25.142,14.1Z" transform="translate(3.179 22.466)" />
                                    <rect id="Rectangle-28" width="8.429" height="67.434" rx="1" transform="matrix(0.966, 0.259, -0.259, 0.966, 55.195, 16.881)" opacity="0.3" />
                                </g>
                            </svg>
                            Web Geliştirme
                        </div>
                        <div className="box">
                           <Link to="/arama-motoru-optimizasyonu/"></Link>
                            <svg style={{ marginLeft: -7 }} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 79 80">
                                <g id="Icons-_-Shopping-_-Chart-line_1" data-name="Icons-/-Shopping-/-Chart-line#1" transform="translate(-0.462 0.373)">
                                    <rect id="bound" width="79" height="80" transform="translate(0.462 -0.373)" fill="none" />
                                    <path id="Path-95" d="M9.6,55.773H59.071a3.3,3.3,0,0,1,0,6.6H6.3a3.3,3.3,0,0,1-3.3-3.3V6.3a3.3,3.3,0,0,1,6.6,0Z" transform="translate(6.895 7.094)" fill="#000000" />
                                    <path id="Path-97" d="M12.7,33.493a3.3,3.3,0,0,1-4.812-4.512L20.261,15.788a3.3,3.3,0,0,1,4.547-.253l9.762,8.33L47.289,7.755a3.3,3.3,0,1,1,5.178,4.088l-14.842,18.8a3.3,3.3,0,0,1-4.73.465L22.92,22.6Z" transform="translate(16.088 15.138)" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                            SEO
                        </div>
                        <div className="box">
                          <Link to="/eposta-pazarlama/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 86 86">
                                <g id="Icons-_-Communication-_-Mail-heart" data-name="Icons-/-Communication-/-Mail-heart" transform="translate(0.169 -0.307)">
                                    <rect id="bound" width="86" height="86" transform="translate(-0.169 0.307)" fill="none" />
                                    <path id="Combined-Shape" d="M8.569,2H51.4a3.569,3.569,0,0,1,3.569,3.569V41.262A3.569,3.569,0,0,1,51.4,44.831H8.569A3.569,3.569,0,0,1,5,41.262V5.569A3.569,3.569,0,0,1,8.569,2Zm27.84,7.138c-2.3,0-4.985,2.605-6.425,4.283-1.439-1.678-4.127-4.283-6.425-4.283-4.068,0-6.425,3.173-6.425,7.21q0,6.71,12.849,14.2,12.849-7.5,12.849-13.92C42.834,12.6,40.477,9.138,36.409,9.138Z" transform="translate(13.236 5.138)" fill="#111" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Combined-Shape-2" data-name="Combined-Shape" d="M5.829,6.816,35.123,27.972,64.417,6.816a1.785,1.785,0,0,1,2.829,1.447V44.034a7.138,7.138,0,0,1-7.138,7.138H10.138A7.138,7.138,0,0,1,3,44.034V8.263A1.785,1.785,0,0,1,5.829,6.816Z" transform="translate(8.097 16.643)" fill="#111" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Eposta Pazarlama
                        </div>
                    </div>
                </div>
                <div className="project-details">
                    <h3 style={{ margin: '1px 0' }}>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Yöneticisi:</li>
                            <li>Web Geliştirme</li>
                            <li>SEO</li>
                            <li>Adwords Specialist</li>
                            <li>İçerik Pazarlama</li>
                        </ul>
                        <ul>
                            <li><img src={dilara} />Dilara Karakuş</li>
                            <li><img src={zeynep} />Zeynep Oralel</li>
                            <li><img src={bengu} />Bengü Dinçer</li>
                            <li><img src={ilker}  />İlker Emir</li>
                            <li><img src={aysima} />Aysima Bakırcılar</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        <div className="container">

            <div className="content-section content row" >
                <div className="col-md-6">
                    <img className="pr100" src={mockup}  />
                </div>
                <div className="col-md-6">
                    <p className="small" style={{color:"#ea4f6a" }} >Başarı Hikayesi</p>
                    <p style={{ maxWidth: 429 }} ><b>Web geliştirme, dijital danışmanlık ve performans pazarlama a</b>
                    hizmetleri vermekte olduğumuz Mocaco Coffee’nin önce yerelde, sonra ulusalda tanınan ve online kahve satışı 
                    alanında fark yaratmaya devam etmesi için çalışmalarımızı sürdürmekteyiz.</p>
                </div>
            </div>
            <div className="referances-detail container"> 
            <div className="col-md-1">   
                </div>
                <div className="col-md-11 referances-boxs" style={{margintop:50}}>
                    <div className="boxs" style={{ marginBottom: '0' }}>
                        <div className="box enerjisa-kutu" style={{ width:180, padding:'20px 10px' }} >
                            <span style={{fontSize:25, fontWeight:700}}>+%114</span>
                            <p style={{fontWeight:100}}>E-ticaret geliri</p>
                        </div>
                        <div className="box enerjisa-kutu" style={{ width:180, padding:20}} >
                            <span style={{fontSize:25, fontWeight:700}}>+%75</span>
                            <p style={{fontWeight:100}}>Online sipariş adeti</p>
                        </div>
                        <div className="box enerjisa-kutu" style={{ width:180, padding:20}} >
                            <span style={{fontSize:25, fontWeight:700}}>9.5k</span>
                            <p style={{fontWeight:100}}>Aylık edinilen organik trafik</p>
                        </div>
                        <div className="box enerjisa-kutu" style={{ width:180, padding:20}} >
                            <span style={{fontSize:25, fontWeight:700}}>+%11</span>
                            <p style={{fontWeight:100}}>Tekrarlayan sipariş oranı</p>
                        </div>
                    </div>
                </div>
               
            </div>
            <div style={{ margin: '50px auto 5px' }} className="text-center">
                <img style={{ width: '90%', margin: '0 auto' }} src={mc} />
            </div>
          
        </div>
        <div className="fullwidth-image yesil referances-detail">
            <div style={{ display: 'flex', alignItems: 'center' }} className="container">
                <div className="col-md-2"></div>
                <div className="col-md-8"><img src={oran} alt="mocaco coffee" /></div>
                <div className="col-md-2"></div>
            </div>
        </div>

        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
